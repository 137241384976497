<template>
  <NuxtLink
    v-if="props.to"
    :to="to"
    class="group mb-3 flex grow items-center gap-2 rounded-md px-3 py-2 hover:cursor-pointer hover:text-gray-900 dark:hover:text-gray-100"
    :class="linkClasses"
  >
    <UIcon :name="props.iconName" class="size-6" />
    <span class="text-sm font-medium">{{ name }}</span>
  </NuxtLink>
  <div
    v-else
    class="group mb-3 flex items-center gap-2 rounded-md px-3 py-2 hover:cursor-pointer hover:text-gray-900 dark:hover:text-gray-100"
    :class="linkClasses"
    @click="isOpen = !isOpen"
  >
    <UIcon :name="props.iconName" class="size-6" />
    <span class="text-sm font-medium">{{ name }}</span>
  </div>
  <Transition>
    <div v-if="subLinks.length > 0 && isOpen" class="mb-10 ml-5 flex flex-col space-y-4">
      <MenuSidebarSubLink v-for="subLink in props.subLinks" :key="subLink.name" :name="subLink.name" :to="subLink.to" />
    </div>
  </Transition>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    exact?: boolean
    iconName: string
    name: string
    to?: string
    subLinks?: SidebarSubLink[]
  }>(),
  {
    exact: false,
    subLinks: () => [],
  },
)

const isActive = computed(() => {
  // Can either have to or sublinks
  if (props.to) {
    if (props.exact) {
      return useRoute().path === props.to
    }
    return useRoute().path.startsWith(props.to)
  }
  else {
    // Only check the sublinks
    return props.subLinks.some(subLink => useRoute().path.startsWith(subLink.to))
  }
})

const isOpen = ref(isActive.value)

// Compute the css classes based on the active state and the isOpen state
const linkClasses = computed(() => {
  return {
    'text-gray-950 dark:text-gray-50 bg-gray-100 dark:bg-gray-800': isActive.value,
    'text-gray-700 dark:text-gray-300': !isActive.value,
  }
})
</script>

<style scoped>
.v-enter-active {
  transition: all 300ms ease;
  transition-delay: 300ms;
}
.v-leave-active {
  transition: all 100ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
