<template>
  <NuxtLink :to="to" :class="isActiveClasses" class="text-sm hover:text-gray-900 hover:dark:text-gray-100">{{ name }}</NuxtLink>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  name: string
  to: string
  exact?: boolean
}>(), {
  exact: false,
})

const isActive = computed(() => {
  if (props.exact) {
    return useRoute().path === props.to
  }
  return useRoute().path.startsWith(props.to)
})

const isActiveClasses = computed(() => {
  return {
    'underline text-gray-900 dark:text-gray-200': isActive.value,
    'text-gray-600 dark:text-gray-400': !isActive.value,
  }
})
</script>

<style></style>
