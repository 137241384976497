<template>
  <aside
    ref="sidebar"
    class="my-3 ml-3 flex w-full max-w-64 flex-col justify-between rounded-lg border border-gray-200 bg-white p-6 shadow-md transition-all duration-300 dark:border-gray-700 dark:bg-gray-900 dark:shadow-none"
  >
    <div class="flex flex-col">
      <MenuSidebarHead @toggle="animateSidebar" />
      <div class="mt-24 flex flex-col">
        <MenuSidebarLink name="Dashboard" exact icon-name="i-heroicons-chart-pie-solid" :to="`/${domain}`" />
        <MenuSidebarLink
          name="Workflows"
          icon-name="i-heroicons-view-columns-solid"
          :sub-links="workflowSublinks"
        />
        <MenuSidebarLink name="Aufgaben" icon-name="i-heroicons-inbox-stack-solid" :to="`/${domain}/tasks`" />
        <MenuSidebarLink
          v-if="useAuth().user?.role === 'admin'"
          name="Einstellungen"
          icon-name="i-heroicons-cog-solid"
          :sub-links="adminSettingsSublinks"
        />
      </div>
    </div>
    <MenuSidebarFooter />
  </aside>
</template>

<script setup lang="ts">
const user = useAuth().user
const domain = ref(user?.organisation?.domain)
const minimized = ref(false)

const sidebar: Ref<HTMLElement | null> = ref(null)
const sideBarWidth = ref('')

// Links
const workflowSublinks = [
  { name: 'Workflows', to: `/${domain.value}/workflows` },
  { name: 'Vorlagen', to: `/${domain.value}/blueprints` },
]

const adminSettingsSublinks = [
  { name: 'Benutzer', to: `/${domain.value}/settings/users` },
  { name: 'Abteilungen', to: `/${domain.value}/settings/departments` },
  { name: 'Organisation', to: `/${domain.value}/settings/organisation` },
]

onMounted(() => {
  if (!minimized.value) {
    sideBarWidth.value = `${284}px`
  }
  else {
    sideBarWidth.value = `${84}px`
  }
})

// Watch for changes in the minimized variable and animate the sidebar
function animateSidebar() {
  if (minimized.value) {
    sideBarWidth.value = `${284}px`
  }
  else {
    sideBarWidth.value = `${84}px`
  }
  minimized.value = !minimized.value
}
</script>

<style scoped>
/* Animation for the search */
.v-enter-active {
  transition: all 300ms ease;
  transition-delay: 100ms;
}
.v-leave-active {
  transition: all 100ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
