<template>
  <UDropdown :items="items">
    <UAvatar src="https://avatars.githubusercontent.com/u/739984?v=4" size="md" chip-color="primary" chip-text="" chip-position="bottom-right" />

    <template #account="{ item }">
      <div class="text-left">
        <p class="text-sm text-gray-600 dark:text-gray-400">Eingeloggt als</p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ item.label }}
        </p>
      </div>
    </template>

    <!-- <template #item="{ item }">
      <span class="truncate">{{ item.label }}</span>

      <UIcon :name="item.icon" class="ms-auto h-4 w-4 flex-shrink-0 text-gray-400 dark:text-gray-500" />
    </template> -->
  </UDropdown>
</template>

<script setup>
const user = useAuth().user
const fullName = `${user?.firstName} ${user?.lastName}`

const items = [
  [
    {
      label: fullName,
      slot: 'account',
      disabled: true,
    },
  ],
  [
    {
      label: 'Profil',
      icon: 'i-heroicons-user-circle-solid',
      click: async () => await navigateTo(`/${user.organisation.domain}/profile`),
    },
  ],
  [
    {
      label: 'Dokumentation',
      icon: 'i-heroicons-book-open-solid',
      click: async () => await navigateTo('/help'),
    },
    // {
    //   label: 'Logout',
    //   icon: 'i-heroicons-arrow-left-start-on-rectangle-solid',
    //   click: async () => {
    //     try {
    //       await useAuth().logoutUser()
    //       await navigateTo('/')
    //     }
    //     catch (error) {
    //       console.error(error)
    //     }
    //   },
    // },
  ],
]
</script>
