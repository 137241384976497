<template>
  <div class="flex items-center justify-between">
    <h1>{{ title }}</h1>
    <div class="flex items-center gap-3">
      <!-- <div class="flex items-center justify-center gap-4" @click="isCommandPaletteOpen = true">
        <UInput placeholder="Suche..." icon="i-heroicons-magnifying-glass" @change="console.log('asd')">
          <template #trailing>
            <div class="flex items-center gap-0.5">
              <UKbd>{{ metaSymbol }}</UKbd>
              <UKbd>K</UKbd>
            </div>
          </template>
        </UInput>
        <div class="size-9 rounded-full p-2 text-primbgr-800 hover:bg-gray-50/40 hover:text-prim-400 dark:text-prim-50 dark:hover:bg-gray-50/20 dark:hover:text-prim-400">
          <UIcon name="i-heroicons-magnifying-glass" class="size-5" />
        </div>
      </div> -->
      <UINotifications />
    </div>
    <Teleport to="body">
      <UModal v-model="isCommandPaletteOpen">
        <UCommandPalette
          v-model="selected"
          multiple
          nullable
          :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'gray', variant: 'link', padded: false }"
          :groups="[{ key: 'people', commands: people }]"
        />
      </UModal>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  title: string
}>()

// const { metaSymbol } = useShortcuts()

const isCommandPaletteOpen = ref(false)

const people = [
  { id: 1, label: 'Wade Cooper' },
  { id: 2, label: 'Arlene Mccoy' },
  { id: 3, label: 'Devon Webb' },
  { id: 4, label: 'Tom Cook' },
  { id: 5, label: 'Tanya Fox' },
  { id: 6, label: 'Hellen Schmidt' },
  { id: 7, label: 'Caroline Schultz' },
  { id: 8, label: 'Mason Heaney' },
  { id: 9, label: 'Claudie Smitham' },
  { id: 10, label: 'Emil Schaefer' },
]

const selected = ref([])
</script>

<style scoped></style>
