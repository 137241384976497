<template>
  <div class="flex min-h-screen bg-gray-50 dark:bg-gray-950">
    <MenuSidebar class="min-w-max" />
    <div class="relative h-screen w-full p-6">
      <UIProtectedHeader :title="title" />
      <main class="mt-16 px-2">
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
const title = computed(() => {
  return (useRoute().meta.title as string) || ''
})
</script>
