<template>
  <UDropdown :items="items">
    <Icon
      name="i-heroicons-bell"
      class="size-6 rounded-full p-2 text-gray-800 hover:bg-gray-600 hover:text-prim-400 dark:text-gray-200 dark:hover:bg-gray-400 dark:hover:text-prim-400"
    />

    <template #item="{ item }">
      <div class="flex flex-col justify-start px-3 py-2 text-left">
        <span class="text-lg font-medium">{{ item.label }}</span>
        <span class="text-sm">{{ item.description }}</span>
      </div>
    </template>
  </UDropdown>
</template>

<script setup>
const items = [
  [
    {
      label: 'Documentation',
      description: 'This is a notification with a description that is a little bit longer',
    },
    {
      label: 'Changelog',
      description: 'This is a notification with a description',
    },
    {
      label: 'Status',
      description: 'This is a notification with a description',
    },
    {
      label: 'Notification 1',
      description: 'This is a notification with a description',
    },
  ],
]
</script>
