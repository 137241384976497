<template>
  <div>
    <div class="mb-6 flex items-center justify-between px-2">
      <UIUserDropdown />
      <UIThemeToggler />
    </div>
    <UButton color="acc" variant="outline" trailing icon="i-heroicons-arrow-left-start-on-rectangle-solid" block @click="logout">
      Logout
    </UButton>
  </div>
</template>

<script setup lang="ts">
// defineProps<{
//   minimized: boolean
// }>()
async function logout() {
  try {
    await useAuth().logoutUser()
    await navigateTo('/')
  }
  catch (error) {
    console.error(error)
  }
}
</script>
